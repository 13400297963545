import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import Button from "../../../components/custom-widgets/button";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import CardGeneric from "../../../components/card/card-generic";
import ModalGeneric from "../../../components/custom-widgets/modal-generic";
import LoginHelpModalDataSpanish from "../../../components/login/login-help-modal-data-spanish";
import FaqAccordion from "../../../components/faq/faq-accordion";
import Icon from "../../../components/custom-widgets/icon";

// Helpers
import ZipCodeFilter from "../../../components/zip-code-filter/zip-code-filter";

// Styles
import styles from "../../../pages/commercial-banking/treasury-management-services/express.module.scss";

const CreditCardsSpanish = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const faqAccordionData = {
    id: "spanish-credit-cards-faq-section",
    title: "Preguntas Frecuentes",
    faqCategoryNames: ["Spanish Credit Cards"],
    showContactBar: false
  };

  const [showHelp, setShowHelp] = useState(false);
  const handleCloseHelp = () => setShowHelp(false);
  const handleShowHelp = (e) => {
    e.preventDefault();
    setShowHelp(true);
  };

  const [showCashBackCardRate, setShowCashBackCardRate] = useState(false);
  const toggleShowCashBackCardRate = () => {
    setShowCashBackCardRate(!showCashBackCardRate);
  };

  const [showSecureCreditCardData, setShowSecureCreditCardData] = useState(false);
  const toggleShowSecureCreditCardData = () => {
    setShowSecureCreditCardData(!showSecureCreditCardData);
  };

  const [showZipCodeModal, setShowZipCodeModal] = useState(false);
  const handleCloseZipCodeModal = () => setShowZipCodeModal(false);
  const handleShowZipCodeModal = () => {
    setShowZipCodeModal(true);
  };

  const title = "Tarjeta de crédito con cash back";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content:
          "Toda la información que necesita sobre las tarjetas de crédito bancarias de WaFd Bank para que pueda solicitar una tarjeta de crédito hoy mismo"
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content:
          "Toda la información que necesita sobre las tarjetas de crédito bancarias de WaFd Bank para que pueda solicitar una tarjeta de crédito hoy mismo"
      },
      {
        property: "og:url",
        content: "https://wafdbank.com/es/banca-personal/tarjetas-de-credito"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-personal-credit-card.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca personal"
    },
    {
      id: 2,
      active: true,
      title: "Tarjetas de crédito"
    }
  ];

  const wafdCardsBtnData = {
    id: "credit-card-sign-in-btn",
    externalLink: true,
    class: "btn-success btn-md btn-block no-max-width text-center",
    text: "Ingresar",
    showIcon: false,
    url: "https://www.ezcardinfo.com/?WAFD=/",
    target: "blank",
    style: {
      minWidth: "0"
    }
  };

  const loginHelpModalData = {
    handleClose: handleCloseHelp,
    show: showHelp,
    modalSize: "md",
    header: {
      id: "cc-sign-in-help-modal-title",
      class: "mb-0",
      text: "Ayuda para Banca en Linea"
    },
    body: {
      class: "",
      content: [<LoginHelpModalDataSpanish />]
    }
  };

  const loginCardInfo = {
    cardClass: "border",
    segments: [
      {
        type: "card-body",
        class: "px-md-4",
        content: [
          <h5 className="card-title">Inicia sesión en tarjetas de crédito de WaFd Bank (eZCard)</h5>,
          <Button {...wafdCardsBtnData} />
        ]
      },
      {
        type: "card-footer",
        class: "bg-light",
        content: [
          <>
            <div>
              ¿Nuevo usuario?{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.ezcardinfo.com/?WAFD="
                id="es-enroll-online-access"
              >
                Regístrate
              </a>
            </div>
            {showHelp && <ModalGeneric {...loginHelpModalData} />}
            <div className="text-right">
              {/* eslint-disable-next-line */}
              <button
                id="es-login-help"
                className={`text-primary ${styles.buttonTransparent}`}
                onClick={handleShowHelp}
              >
                <Icon name="question-circle" lib="far" class="mr-1" />
                Ayuda
              </button>
            </div>
          </>
        ]
      }
    ]
  };

  const heroChevronData = {
    id: "credit-cards-hero",
    ...getHeroImgVariables(imgData),
    altText: "Mujer realizando un pago de contacto con tarjeta de crédito en el mostrador de una tienda.",
    chevronBgClass: "bg-green-60",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Tasas bajas y hasta 2% de cash back"
          }
        },
        {
          id: 3,
          button: {
            id: "m-credit-cards-cta",
            class: "btn-white w-sm-75",
            text: "Solicítala hoy",
            onClick: handleShowZipCodeModal,
            type: "button"
          }
        }
      ]
    }
  };

  const cashBackDocumentsListData = [
    {
      id: "cash-back-rewards-visa-and-visa-signature-rate",
      text: "WaFd Bank Cash Back Rewards Visa<sup>&reg;</sup> and Visa Signature<sup>&reg;</sup> Rate",
      url: "/documents/credit-cards/wafd-bank-cash-back-rewards-visa-signature-rate.pdf"
    },
    {
      id: "cash-back-rewards-visa-signature-cardholder-agreement",
      text: "WaFd Bank Cash Back Rewards Visa Signature<sup>&reg;</sup> Cardholder Agreement",
      url: "/documents/credit-cards/wafd-bank-cash-back-rewards-visa-signature-cardholder-agreement.pdf"
    },
    {
      id: "cash-back-rewards-visa-cardholder-agreement",
      text: "WaFd Bank Cash Back Rewards Visa<sup>&reg;</sup> Cardholder Agreement",
      url: "/documents/credit-cards/wafd-bank-cash-back-rewards-visa-cardholder-agreement.pdf"
    },
    {
      id: "visa-guide-to-benefits-signature-tier-version",
      text: "Visa<sup>&reg;</sup> Guide to Benefits (Signature tier version)",
      url: "/documents/credit-cards/visa-guide-to-benefits-(signature-tier-version).pdf"
    },
    {
      id: "visa-guide-to-benefits-traditional-tier-version",
      text: "Visa<sup>&reg;</sup> Guide to Benefits (Traditional tier version)",
      url: "/documents/credit-cards/visa-guide-to-benefits-(traditional-tier-version).pdf"
    },
    {
      id: "scorecard-cash-back-rewards-program-rules",
      text: "ScoreCard<sup>&reg;</sup> Cash Back Rewards Program Rules",
      url: "/documents/credit-cards/scorecard-cash-back-rewards-program-rules.pdf"
    },
    {
      id: "secured-credit-card-interest-rate-fee-schedule",
      text: "WaFd Bank Secured Visa<sup>&reg;</sup> Credit Card Interest Rate and Fee Schedule",
      url: "/documents/credit-cards/wafdbank-secured-interest-rate-fee-schedule.pdf"
    },
    {
      id: "secured-credit-card-cardholder-agreement",
      text: "WaFd Bank Secured Visa<sup>&reg;</sup> Credit Card Cardholder Agreement",
      url: "/documents/credit-cards/wafdbank-secured-credit-card-cardholder-agreement.pdf"
    }
  ];

  const creditCardIconsData = [
    {
      title: "Sin cuota anual",
      imageSource: "../../../images/icons/icon-no-annual-fee.svg",
      id: "sin-cuota-anual"
    },
    {
      title: "1% Cash Back",
      text: "ganado por todas las compras elegibles y emitido como crédito en el estado de cuenta automatizado - ¡No lo necesitas canjear!",
      imageSource: "../../../images/icons/icon-1percent-cash-back.svg",
      id: "one-percent-cash-back"
    },
    {
      title: "Hasta 2% de Cash Back<sup>*</sup>",
      text: "en compras de gas, abarrotes y farmacia para tarjetahabientes de Visa Signature<sup>&reg;</sup>.",
      imageSource: "../../../images/icons/icon-2percent-cash-back.svg",
      id: "hasta-two-percent-cash-back"
    },
    {
      title: "Roadside Dispatch<sup>&reg;</sup>",
      text: "<sup>**</sup>Obtén seguridad y comodidad dondequiera que te lleven tus viajes.",
      imageSource: "../../../images/icons/icon-roadside-dispatch.svg",
      id: "roadside-dispatch"
    },
    {
      title: "ID Navigator",
      text: "<sup>**</sup>Obtén mayor tranquilidad con la protección contra el robo de identidad.",
      imageSource: "../../../images/icons/icon-id-navigator.svg",
      id: "id-navigator"
    },
    {
      title: "Servicios de Asistencia en Viajes y Emergencias",
      text: "<sup>**</sup>en escenarios elegibles para tarjetas Visa Signature<sup>&reg;</sup>.",
      imageSource: "../../../images/icons/icon-travel-emergency-assistance.svg",
      id: "servicios-de-asistencia-en-viajesy-emergencias"
    }
  ];

  const secureCreditCardIconsData = [
    {
      title: "Sólo clientes actuales de WaFd Bank",
      imageSource: "../../../images/icons/icon-wafd-clients.svg",
      id: "secure-cc-wafd-clients"
    },
    {
      title: "Sin tarifa anual ni cargos financieros ocultos",
      imageSource: "../../../images/icons/icon-no-annual-fee.svg",
      id: "secure-cc-no-annual-fee"
    },
    {
      title: "Genera crédito",
      text: "Con reportes a cuatro de los principales burós de crédito.",
      imageSource: "../../../images/icons/icon-credit.svg",
      id: "secure-cc-build-credit"
    },
    {
      title: "Roadside Dispatch<sup>&reg;</sup>",
      text: "<sup>**</sup>Obtén seguridad y comodidad dondequiera que te lleven tus viajes.",
      imageSource: "../../../images/icons/icon-roadside-dispatch.svg",
      id: "roadside-dispatch"
    },
    {
      title: "ID Navigator",
      text: "<sup>**</sup>Obtén mayor tranquilidad con la protección contra el robo de identidad.",
      imageSource: "../../../images/icons/icon-id-navigator.svg",
      id: "id-navigator"
    }
  ];

  const CreditCardIconsSection = (props) => {
    return (
      <div className="row row-cols-1 row-cols-sm-3">
        {props.data.map((block, index) => {
          return (
            <div key={index} id={block.id} className="text-center mb-3 px-2">
              <img src={block.imageSource} alt={`${block.title} Icon`} className="mb-3" style={{ width: "96px" }} />
              <h3 className="font-weight-bold mb-0" dangerouslySetInnerHTML={{ __html: block.title }} />
              {block.text ? <h4 dangerouslySetInnerHTML={{ __html: block.text }} /> : null}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container border-bottom">
        <div className="row">
          <div className="col-12 col-lg-7 col-xl-8">
            <h1>Tarjetas de crédito WaFd con tarifas competitivas y grandes recompensas.</h1>
            <h4>
              Ya sea que desee un reembolso en efectivo o la oportunidad de ganar recompensas, es fácil{" "}
              {/* <a
                href="https://creditcards.wafdbank.com/apply/?store=wafdbank&product=wafd-bank-cash-back-rewards-visa-credit-card"
                target="_self"
              >
                solicitarlo en línea
              </a> */}
              <button
                id="wafd-cash-back-rewards-visa-apply-link"
                className="btn-anchor-link"
                onClick={handleShowZipCodeModal}
              >
                solicitarlo en línea
              </button>
              .
            </h4>
          </div>
          <div className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
            <CardGeneric {...loginCardInfo} />
          </div>
        </div>
      </section>
      <section className="container border-bottom pb-0" id="apply-now-section">
        <h2>Tarjeta Cash Back Credit Card de WaFd Bank</h2>
        <div className="row py-sm-1">
          <div className="col-md-3 text-center">
            <StaticImage
              src="../../../images/credit-cards/thumbnail-credit-signature-horizontal.jpg"
              alt="Tarjeta WaFd Bank Cash Back Credit Card"
              placeholder="blurred"
              className="mx-auto text-center "
              quality="100"
            />

            <div className="mb-5 align-items-sm-start d-flex flex-column">
              {/* <Button {...cashBackApplyNowButtonData} /> */}
              <div className="w-100 my-3">
                <button
                  id="credit-card-apply-now-cta"
                  className="btn btn-primary btn-block no-min-width"
                  onClick={handleShowZipCodeModal}
                >
                  Aplica Ya
                </button>
              </div>
              <ZipCodeFilter
                showModal={showZipCodeModal}
                handleCloseModal={handleCloseZipCodeModal}
                eligibleUrl={
                  "https://creditcards.wafdbank.com/apply/?store=wafdbank&product=wafd-bank-cash-back-rewards-visa-credit-card"
                }
              />
              <Link
                to="#terms-and-conditions-section"
                id="terms-and-conditions-section-anchor-link"
                className="text-decoration-none"
              >
                <Icon name="arrow-down" class="mr-2 mt-1" />
                Términos y condiciones
              </Link>
              <button
                className="btn btn-anchor-link d-flex my-2 text-left"
                onClick={toggleShowCashBackCardRate}
                id="cash-back-cc-rate-btn"
              >
                <Icon lib="fas" name={showCashBackCardRate ? "chevron-up" : "chevron-down"} class="mt-1 mr-2" />
                Tasa de la tarjeta Cash Back Credit Card
              </button>
              {showCashBackCardRate && (
                <div className="text-green-60 font-weight-bold">17.74% Tasa de Porcentaje Anual (APR) para Compras</div>
              )}
            </div>
          </div>
          <div className="col-md-9">
            <CreditCardIconsSection data={creditCardIconsData} />
          </div>
        </div>
      </section>
      <section className="container pb-0">
        <h2 className="font-weight-bold">¿Buscas reparar o construir tu crédito?</h2>
        <h2>Tarjeta Secured Credit Card de WaFd Bank</h2>
        <div className="row py-sm-1">
          <div className="col-md-3 text-left">
            <StaticImage
              src="../../../images/credit-cards/thumbnail-credit-signature-horizontal.jpg"
              alt="WaFd Bank Cash Back Credit Card"
              placeholder="blurred"
              className="mx-auto text-center "
              quality="100"
            />

            <div className="mb-5 align-items-sm-start d-flex flex-column">
              <div className="w-100 my-3">
                <Link id="contact-local-banker" to="" className="btn btn-primary btn-block no-min-width">
                  Contacta con un banquero local
                </Link>
              </div>
              <Link
                to="#terms-and-conditions-section"
                id="terms-and-conditions-section-anchor-link-2"
                className="text-decoration-none"
              >
                <Icon name="arrow-down" class="mr-2 mt-1" />
                Términos y condiciones
              </Link>
              <button
                className="btn btn-anchor-link d-flex my-2 text-left"
                onClick={toggleShowSecureCreditCardData}
                id="cash-back-secure-cc-rate-btn"
              >
                <Icon lib="fas" name={showSecureCreditCardData ? "chevron-up" : "chevron-down"} class="mt-1 mr-2" />
                Tasa de la tarjeta Cash Back Credit Card
              </button>
              {showSecureCreditCardData && (
                <div className="text-green-60 font-weight-bold pl-4">
                  Tasa de porcentaje anual (APR) del 25.49% para compras
                </div>
              )}
            </div>
          </div>
          <div className="col-md-9">
            <CreditCardIconsSection data={secureCreditCardIconsData} />
          </div>
        </div>
      </section>
      <FaqAccordion {...faqAccordionData} />
      <section id="terms-and-conditions-section" className="container pt-0 text-sm text-gray-60 pt-0">
        <h5>Términos y condiciones</h5>
        <ul className="my-3 list-unstyled">
          {cashBackDocumentsListData.map((document) => (
            <>
              <li key={document.id}>
                <a
                  id={document.id}
                  href={document.url}
                  target="_blank"
                  className="text-decoration-none"
                  rel="noreferrer"
                >
                  <Icon class="mr-2" name="file-pdf" />
                  <span dangerouslySetInnerHTML={{ __html: document.text }} />
                </a>
              </li>
            </>
          ))}
        </ul>
        <p id="disclaimer-apr">
          <sup>&dagger;</sup>Tasa de Porcentaje Anual (APR) El costo de pedir dinero prestado expresado como porcentaje
          anual.
        </p>
        <p id="es-cash-back-percentages">
          * Se puede ganar un 2% de reembolso en efectivo por compras de gasolina, comestibles y farmacia para los
          titulares de tarjetas Visa Signature<sup>&reg;</sup> y un 1% de reembolso en efectivo por todas las compras
          calificadas para los titulares de tarjetas Visa<sup>&reg;</sup> y Visa Signature<sup>&reg;</sup>.
        </p>
        <p id="es-cash-back-terms">
          ** Se aplican ciertos términos, condiciones y exclusiones. Consulte la Guía de beneficios de Visa y el Acuerdo
          del titular de la tarjeta para obténer más detalles.
        </p>
        <p>
          Revisa el{" "}
          <Link to="/es/privacidad-y-seguridad/proteccion-de-su-privacidad" id="es-cash-back-privacy-notice-link">
            Aviso de privacidad de WaFd Bank
          </Link>
          .
        </p>
        <p>
          ¿Preguntas? Llámanos al <a href="tel:800-324-9375">800-324-9375</a>
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default CreditCardsSpanish;
